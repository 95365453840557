// Cake Mono Light UX
@mixin cake-mono-light-ux {
  font-family: "Cake Mono Light UX", sans-serif;
}

// Cake Mono Light
@mixin cake-mono-light {
  font-family: "Cake Mono Light", sans-serif;
}

// Cake Mono Inverse
@mixin cake-mono-inverse-regular {
  font-family: "Cake Mono Inverse Regular", sans-serif;
}

// Temp
@mixin vandertak {
  font-family: "Vandertak", sans-serif;
}

@mixin cake-sans-regular {
  font-family: "Cake Sans Regular", sans-serif;
}

@mixin whale {
  font-family: "Whale", sans-serif;
}
