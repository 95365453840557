// Home Contain

.home-contain {
  padding-top: 0em;
  padding-bottom: 0em;
  max-width: 1200px;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
}

@include media-breakpoint-up(sm) {
  .home-contain {
    padding-top: 0em;
    padding-bottom: 0em;
    max-width: 1200px;
  }
}

@include media-breakpoint-up(md) {
  .home-contain {
    padding-top: 0em;
    padding-bottom: 0em;
    max-width: 1200px;
  }
}

@include media-breakpoint-up(lg) {
  .home-contain {
    padding-top: 0em;
    padding-bottom: 0em;
    max-width: 1200px;
  }
}

@include media-breakpoint-up(xl) {
  .home-contain {
    padding-top: 0em;
    padding-bottom: 0em;
    max-width: 1200px;
  }
}

// Home Font Large

.home-font-large {
  padding-top: 1em;
  padding-left: 0em;
  padding-right: 0em;
  padding-bottom: 0em;
  border-bottom: 4px solid #000000;
  .home-font-info {
    padding: 0.2em 0em 0.5em 0em;
    margin-bottom: 0.4em;
    font-size: 1rem;
  }
  .pipe {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }
}

@include media-breakpoint-up(sm) {
  .home-font-large {
    padding-top: 1em;
  }
}

@include media-breakpoint-up(md) {
  .home-font-large {
    padding-top: 2em;
  }
}

@include media-breakpoint-up(lg) {
  .home-font-large {
    padding-top: 2em;
  }
}

@include media-breakpoint-up(xl) {
  .home-font-large {
    padding-top: 2em;
  }
}

.home-first {
  border-top: 0px solid #000000;
}

.home-last {
  border-bottom: 0px solid #000000;
}

// Spec Contain
.spec-contain img {
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.spec-contain img:hover {
  cursor: pointer;
}

// New
.new {
  @include cake-mono-inverse-regular;
  padding-right: 1em;
}
