.form-link {
  padding-top: 1em;
}

.account-header {
  border-bottom: 4px solid #000000;
  padding-bottom: 2em;
}

.invoice-header {
  padding-bottom: 2em;
}

.invoice-footer {
  padding-bottom: 2em;
}

.invoice {
  border-bottom: 4px solid #000000;
  padding-bottom: 0.5em;
  margin-bottom: 1.5em;
}

// Account Section
.account-section {
  padding-top: 1.5em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  border-bottom: 4px solid #000000;
  max-width: 1200px;
  .subhead {
    text-transform: uppercase;
    padding-bottom: 1.5em;
  }
  .account-font-name {
    font-size: 2.5rem;
  }
  .account-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid rgb(0, 0, 0);
    border-bottom: 1px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .account-part-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 1px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .cart-fill {
    color: rgba(0, 0, 0, 0);
  }
}

// Account Last Section
.account-last-section {
  padding-top: 1.5em;
  padding-bottom: 0em;
  padding-left: 0em;
  padding-right: 0em;
  max-width: 1200px;
  .subhead {
    text-transform: uppercase;
    padding-bottom: 1.5em;
  }
  .account-font-name {
    font-size: 2.5rem;
  }
  .account-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid rgb(0, 0, 0);
    border-bottom: 1px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .account-part-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 1px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .cart-fill {
    color: rgba(0, 0, 0, 0);
  }
}

// Invoice Section
.invoice-section {
  padding-top: 1.5em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  max-width: 1200px;
  .subhead {
    text-transform: uppercase;
    padding-bottom: 1.5em;
  }
  .invoice-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 4px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .invoice-part-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .invoice-voucher-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 4px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .invoice-subtotal-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .invoice-total-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 4px solid rgb(0, 0, 0);
    border-bottom: 4px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .cart-fill {
    color: rgba(0, 0, 0, 0);
  }
}
