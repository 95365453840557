.breadcrumb-item + .breadcrumb-item::before {
  content: var(--bs-breadcrumb-divider, ">") !important ;
}

.breadcrumb-start + .breadcrumb-start::before {
  content: var(--bs-breadcrumb-divider, "|") !important ;
}

.breadcrumb-disabled {
  pointer-events: none;
  opacity: 0.4;
  a {
    text-decoration: none;
  }
}

// Nav Tabs
.nav-tabs {
  .nav-link {
    margin-right: 0.5em;
  }
}

.payment-links {
  border-bottom: 1px solid #000000;
  padding-bottom: 1.5em;
  .pipe {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }
}

.cc-button {
  background-color: #000000;
  border-radius: none;
  border: none;
  color: white;
  padding: 0.8em 2em 0.9em 2em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
}

// Form Section
.form-section {
  border-top: 1px solid #000000;
  padding-top: 1.5em;
}

// Eula Section
.eula-section {
  padding-top: 1em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  max-width: 1200px;
}

// Eula Section
.validate-section {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0em;
  padding-right: 0em;
  max-width: 1200px;
}

// Payment Summary Section
.payment-section {
  padding-top: 0.5em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  max-width: 1200px;
  .cart-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid rgb(0, 0, 0);
    border-bottom: 1px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .cart-part-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 1px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
}

#payment-form button {
  background-color: #000000;
  border-radius: none;
  border: none;
  color: white;
  margin-top: 1em;
  padding: 0.8em 2em 0.9em 2em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1rem;
}
