// Tester Contain
.tester-contain {
  padding-top: 0em;
  padding-bottom: 0em;
  padding-left: 0em;
  padding-right: 0em;
  border-bottom: 4px solid #000000;
  max-width: 1200px;

  [contenteditable]:focus {
    outline: 0px solid transparent;
  }
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .pdf-char-set {
    display: inline;
  }
  // Unit Big
  .unit-big {
    // Size Unit
    .size-unit {
      padding-top: 0.4em;
      padding-right: 0.6em;
      .range {
        width: 6em;
      }
    }
    // Line Height Unit
    .line-height-unit {
      padding-top: 0.4em;
      padding-right: 0.6em;
      .range {
        width: 6em;
      }
    }
    // Align Unit
    .align-unit {
      padding-top: 0.4em;
      padding-right: 0.6em;
      .bi {
        font-size: 1.2rem;
      }
    }
    @-moz-document url-prefix() {
      .align-unit {
        padding-top: 0em;
        padding-right: 0.6em;
      }
      .bi {
        font-size: 1.2rem;
      }
    }
    // Char Unit
    .char-unit {
      padding-top: 0.55em;
      .btn {
        margin-right: 0.5rem;
      }
    }
    @-moz-document url-prefix() {
      .char-unit {
        padding-top: 0.2em;
        .btn {
          margin-right: 0.5rem;
        }
      }
    }
    // Options Unit
    .options-unit {
      padding-top: 0.55em;
      padding-right: 0;
    }
    @-moz-document url-prefix() {
      .options-unit {
        padding-top: 0.2em;
        padding-right: 0;
      }
    }
    // Tester Nav
    .tester-nav {
      margin-bottom: 0.2em;
      position: relative;
    }
    // // Text Unit
    // .text-unit {
    //   margin-top: 0.2em;
    // }
  }
  // Unit Small
  .unit-small {
    .size-unit {
      padding-top: 1em;
      padding-bottom: 1em;
      padding-right: 0.6em;
      .range {
        width: 100%;
      }
    }
    @include media-breakpoint-up(sm) {
      .size-unit {
        padding-top: 0em;
        padding-right: 0.6em;
        .range {
          width: 100%;
        }
      }
    }
    @include media-breakpoint-up(md) {
      .size-unit {
        padding-top: 0em;
        padding-right: 0.6em;
        .range {
          width: 100%;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      .size-unit {
        padding-top: 0em;
        padding-right: 0.6em;
        .range {
          width: 100%;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      .size-unit {
        padding-top: 0em;
        padding-right: 0.6em;
        .range {
          width: 100%;
        }
      }
    }
    .text-unit {
      margin-top: 0em;
    }
    // Char Unit
    .char-unit {
      .btn {
        margin-right: 0.5rem;
      }
    }
    @-moz-document url-prefix() {
      .char-unit {
        .btn {
          margin-right: 0.5rem;
        }
      }
    }
    // Weight Unit
    .weight-unit {
      padding-top: 0.5em;
      padding-right: 0;
    }
    @-moz-document url-prefix() {
      .weight-unit {
        padding-top: 0.18em;
        padding-right: 0;
      }
    }
    // Options Unit
    .options-unit {
      padding-top: 0.55em;
      padding-right: 0;
    }
    @-moz-document url-prefix() {
      .options-unit {
        padding-top: 0.2em;
        padding-right: 0;
      }
    }
  }

  .font-name {
    padding-top: 1em;
  }
  .header {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-bottom: 1em;
  }
}

@include media-breakpoint-up(sm) {
  .tester-contain {
    padding-top: 0em;
    padding-bottom: 1em;
    padding-left: 0em;
    padding-right: 0em;
    max-width: 1200px;
  }
}

@include media-breakpoint-up(md) {
  .tester-contain {
    padding-top: 0em;
    padding-bottom: 1em;
    padding-left: 0em;
    padding-right: 0em;
    max-width: 1200px;
  }
}

@include media-breakpoint-up(lg) {
  .tester-contain {
    padding-top: 0em;
    padding-bottom: 1em;
    padding-left: 0em;
    padding-right: 0em;
    max-width: 1200px;
  }
}

@include media-breakpoint-up(xl) {
  .tester-contain {
    padding-top: 0em;
    padding-bottom: 1em;
    padding-left: 0em;
    padding-right: 0em;
    max-width: 1200px;
  }
}

// Font Description
.font-desc {
  padding-top: 4em;
  padding-bottom: 8em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

@include media-breakpoint-up(sm) {
  .font-desc {
    padding-top: 4em;
    padding-bottom: 8em;
    padding-left: 3.2em;
    padding-right: 3.2em;
  }
}

@include media-breakpoint-up(md) {
  .font-desc {
    padding-top: 4em;
    padding-bottom: 8em;
    padding-left: 3.2em;
    padding-right: 3.2em;
  }
}

@include media-breakpoint-up(lg) {
  .font-desc {
    padding-top: 4em;
    padding-bottom: 8em;
    padding-left: 3.2em;
    padding-right: 3.2em;
  }
}

@include media-breakpoint-up(xl) {
  .font-desc {
    padding-top: 4em;
    padding-bottom: 8em;
    padding-left: 3.2em;
    padding-right: 3.2em;
  }
}
