//Colors

body {
  background: #f3e6e0;
}

.section-bg {
  background: #f3e6e0;
}

.cake-mono-bg {
  background: #fbed78;
}

.cake-sans-bg {
  background: #a0cfeb;
}

.cake-stencil-bg {
  background: #cc95b7;
}

.vandertak-bg {
  background: #dc9ddd;
}

.whale-bg {
  background: #a1da8b;
}

.receipt-bg {
  background: #f9de81;
}

.cake-type-bg {
  background: #a1cac5;
}

.blahblah-bg {
  background: #ff9c9c;
}

.jurriaan-3d-bg {
  background: #ffaa7b;
}

.oigle-bg {
  background: #00fc7f;
}

.quokka-bg {
  background: #d2c295;
}

.vandertak-capslock-bg {
  background: #cac0b6;
}