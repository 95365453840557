// BlahBlah

@font-face {
  font-family: "BlahBlah";
  src: url("../../../public/fonts/blahblah/BlahBlah-Soft.woff2") format("woff2");
}

@font-face {
  font-family: "BlahBlah Hatch";
  src: url("../../../public/fonts/blahblah/BlahBlah-Hatch.woff2")
    format("woff2");
}

@font-face {
  font-family: "BlahBlah Matrix";
  src: url("../../../public/fonts/blahblah/BlahBlah-Matrix.woff2")
    format("woff2");
}

@font-face {
  font-family: "BlahBlah Soft";
  src: url("../../../public/fonts/blahblah/BlahBlah-Soft.woff2") format("woff2");
}

@font-face {
  font-family: "BlahBlah Solid";
  src: url("../../../public/fonts/blahblah/BlahBlah-Solid.woff2")
    format("woff2");
}

// Cake Mono

@font-face {
  font-family: "Cake Mono";
  src: url("../../../public/fonts/cake-mono/CakeMono-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Mono Light";
  src: url("../../../public/fonts/cake-mono/CakeMono-Light.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Mono Regular";
  src: url("../../../public/fonts/cake-mono/CakeMono-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Mono Bold";
  src: url("../../../public/fonts/cake-mono/CakeMono-Bold.woff2")
    format("woff2");
}

// Cake Mono UX

@font-face {
  font-family: "Cake Mono Light UX";
  src: url("../../../public/fonts/ux/CakeMonoUX-Light.woff2") format("woff2");
}

// Cake Mono Inverse

@font-face {
  font-family: "Cake Mono Inverse Regular";
  src: url("../../../public/fonts/cake-mono-inverse/CakeMonoInverse-Regular.woff2")
    format("woff2");
}

// Cake Sans

@font-face {
  font-family: "Cake Sans";
  src: url("../../../public/fonts/cake-sans/CakeSans-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Sans Light";
  src: url("../../../public/fonts/cake-sans/CakeSans-Light.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Sans Regular";
  src: url("../../../public/fonts/cake-sans/CakeSans-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Sans Bold";
  src: url("../../../public/fonts/cake-sans/CakeSans-Bold.woff2")
    format("woff2");
}

// Cake Stencil

@font-face {
  font-family: "Cake Stencil";
  src: url("../../../public/fonts/cake-stencil/CakeStencil-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Stencil Light";
  src: url("../../../public/fonts/cake-stencil/CakeStencil-Light.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Stencil Regular";
  src: url("../../../public/fonts/cake-stencil/CakeStencil-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Stencil Bold";
  src: url("../../../public/fonts/cake-stencil/CakeStencil-Bold.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Stencil Light Applied";
  src: url("../../../public/fonts/cake-stencil/CakeStencil-LightApplied.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Stencil Regular Applied";
  src: url("../../../public/fonts/cake-stencil/CakeStencil-RegularApplied.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Stencil Bold Applied";
  src: url("../../../public/fonts/cake-stencil/CakeStencil-BoldApplied.woff2")
    format("woff2");
}

// Cake Type

@font-face {
  font-family: "Cake Type";
  src: url("../../../public/fonts/cake-type/CakeType-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Type Light";
  src: url("../../../public/fonts/cake-type/CakeType-Light.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Type Regular";
  src: url("../../../public/fonts/cake-type/CakeType-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "Cake Type Bold";
  src: url("../../../public/fonts/cake-type/CakeType-Bold.woff2")
    format("woff2");
}

// Jurriaan 3D

@font-face {
  font-family: "Jurriaan 3D";
  src: url("../../../public/fonts/jurriaan-3d/Jurriaan3D-Dark.woff2")
    format("woff2");
}

@font-face {
  font-family: "Jurriaan 3D Dark";
  src: url("../../../public/fonts/jurriaan-3d/Jurriaan3D-Dark.woff2")
    format("woff2");
}

@font-face {
  font-family: "Jurriaan 3D Fill";
  src: url("../../../public/fonts/jurriaan-3d/Jurriaan3D-Fill.woff2")
    format("woff2");
}

@font-face {
  font-family: "Jurriaan 3D Ribbed";
  src: url("../../../public/fonts/jurriaan-3d/Jurriaan3D-Ribbed.woff2")
    format("woff2");
}

@font-face {
  font-family: "Jurriaan 3D Shaded";
  src: url("../../../public/fonts/jurriaan-3d/Jurriaan3D-Shaded.woff2")
    format("woff2");
}

// Oigle

@font-face {
  font-family: "Oigle";
  src: url("../../../public/fonts/oigle/Oigle-Regular.woff2") format("woff2");
}

// Quokka

@font-face {
  font-family: "Quokka";
  src: url("../../../public/fonts/quokka/Quokka-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Quokka Regular";
  src: url("../../../public/fonts/quokka/Quokka-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Quokka Glitch Left";
  src: url("../../../public/fonts/quokka/Quokka-GlitchLeft.woff2")
    format("woff2");
}

@font-face {
  font-family: "Quokka Glitch Right";
  src: url("../../../public/fonts/quokka/Quokka-GlitchRight.woff2")
    format("woff2");
}

// Receipt

@font-face {
  font-family: "Receipt";
  src: url("../../../public/fonts/receipt/Receipt-Narrow.woff2") format("woff2");
}

@font-face {
  font-family: "Receipt Narrow";
  src: url("../../../public/fonts/receipt/Receipt-Narrow.woff2") format("woff2");
}

@font-face {
  font-family: "Receipt Wide";
  src: url("../../../public/fonts/receipt/Receipt-Wide.woff2") format("woff2");
}

@font-face {
  font-family: "Receipt Narrow Matrix 1";
  src: url("../../../public/fonts/receipt/Receipt-NarrowMatrix1.woff2") format("woff2");
}

@font-face {
  font-family: "Receipt Narrow Matrix 2";
  src: url("../../../public/fonts/receipt/Receipt-NarrowMatrix2.woff2") format("woff2");
}

@font-face {
  font-family: "Receipt Narrow Line H";
  src: url("../../../public/fonts/receipt/Receipt-NarrowLineH.woff2") format("woff2");
}

@font-face {
  font-family: "Receipt Narrow Line V";
  src: url("../../../public/fonts/receipt/Receipt-NarrowLineV.woff2") format("woff2");
}

// Vandertak

@font-face {
  font-family: "Vandertak";
  src: url("../../../public/fonts/vandertak/Vandertak-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "Vandertak Regular";
  src: url("../../../public/fonts/vandertak/Vandertak-Regular.woff2")
    format("woff2");
}

@font-face {
  font-family: "Vandertak Soft";
  src: url("../../../public/fonts/vandertak/Vandertak-Soft.woff2")
    format("woff2");
}

@font-face {
  font-family: "Vandertak Inline";
  src: url("../../../public/fonts/vandertak/Vandertak-Inline.woff2")
    format("woff2");
}

@font-face {
  font-family: "Vandertak Outline";
  src: url("../../../public/fonts/vandertak/Vandertak-Outline.woff2")
    format("woff2");
}

@font-face {
  font-family: "Vandertak Rough";
  src: url("../../../public/fonts/vandertak/Vandertak-Rough.woff2")
    format("woff2");
}

@font-face {
  font-family: "Vandertak Stencil";
  src: url("../../../public/fonts/vandertak/Vandertak-Stencil.woff2")
    format("woff2");
}

@font-face {
  font-family: "Vandertak Stencil Applied";
  src: url("../../../public/fonts/vandertak/Vandertak-StencilApplied.woff2")
    format("woff2");
}

// Whale

@font-face {
  font-family: "Whale";
  src: url("../../../public/fonts/whale/Whale-Regular.woff2") format("woff2");
}

// Vandertak CAPSLOCK

@font-face {
  font-family: "Vandertak CAPSLOCK";
  src: url("../../../public/fonts/vandertak-capslock/VandertakCAPSLOCK-Side.woff2")
    format("woff2");
}

@font-face {
  font-family: "Vandertak CAPSLOCK Line";
  src: url("../../../public/fonts/vandertak-capslock/VandertakCAPSLOCK-Line.woff2")
    format("woff2");
}

@font-face {
  font-family: "Vandertak CAPSLOCK Black";
  src: url("../../../public/fonts/vandertak-capslock/VandertakCAPSLOCK-Black.woff2")
    format("woff2");
}

@font-face {
  font-family: "Vandertak CAPSLOCK Fill";
  src: url("../../../public/fonts/vandertak-capslock/VandertakCAPSLOCK-Fill.woff2")
    format("woff2");
}

@font-face {
  font-family: "Vandertak CAPSLOCK Side";
  src: url("../../../public/fonts/vandertak-capslock/VandertakCAPSLOCK-Side.woff2")
    format("woff2");
}

@font-face {
  font-family: "Vandertak CAPSLOCK Top";
  src: url("../../../public/fonts/vandertak-capslock/VandertakCAPSLOCK-Top.woff2")
    format("woff2");
}