// Ranges

input[type="range"] {
  -webkit-appearance: none;
  margin: 0px 0;
  width: 100%;
  background: transparent;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: #000000;
}
input[type="range"]::-webkit-slider-thumb {
  height: 15px;
  width: 15px;
  background: #000000;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
  box-shadow: none;
}
@include media-breakpoint-up(sm) {
  input[type="range"]::-webkit-slider-thumb {
    height: 10px;
    width: 10px;
    background: #000000;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
    box-shadow: none;
  }
}
@include media-breakpoint-up(md) {
  input[type="range"]::-webkit-slider-thumb {
    height: 10px;
    width: 10px;
    background: #000000;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
    box-shadow: none;
  }
}
@include media-breakpoint-up(lg) {
  input[type="range"]::-webkit-slider-thumb {
    height: 10px;
    width: 10px;
    background: #000000;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
    box-shadow: none;
  }
}
@include media-breakpoint-up(xl) {
  input[type="range"]::-webkit-slider-thumb {
    height: 10px;
    width: 10px;
    background: #000000;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
    box-shadow: none;
  }
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #000000;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: #000000;
}
input[type="range"]::-moz-range-thumb {
  border: 0px solid #000000;
  height: 15px;
  width: 15px;
  border-radius: 0px;
  background: #000000;
  cursor: pointer;
  box-shadow: none;
}
@include media-breakpoint-up(sm) {
  input[type="range"]::-moz-range-thumb {
    border: 0px solid #000000;
    height: 10px;
    width: 10px;
    border-radius: 0px;
    background: #000000;
    cursor: pointer;
    box-shadow: none;
  }
}
@include media-breakpoint-up(md) {
  input[type="range"]::-moz-range-thumb {
    border: 0px solid #000000;
    height: 10px;
    width: 10px;
    border-radius: 0px;
    background: #000000;
    cursor: pointer;
    box-shadow: none;
  }
}
@include media-breakpoint-up(lg) {
  input[type="range"]::-moz-range-thumb {
    border: 0px solid #000000;
    height: 10px;
    width: 10px;
    border-radius: 0px;
    background: #000000;
    cursor: pointer;
    box-shadow: none;
  }
}
@include media-breakpoint-up(xl) {
  input[type="range"]::-moz-range-thumb {
    border: 0px solid #000000;
    height: 10px;
    width: 10px;
    border-radius: 0px;
    background: #000000;
    cursor: pointer;
    box-shadow: none;
  }
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}
input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #367ebd;
}
