// Html
html {
  scroll-padding-top: 70px;
}

// Body
body {
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  @include cake-mono-light-ux;
  color: rgb(0, 0, 0);
}

::selection {
  background: rgba(255, 255, 255, 0.9);
}

.CookieConsent {
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 30px rgb(233, 233, 233) inset !important;
  box-shadow: 0 0 0 30px rgb(233, 233, 233) inset !important;
}
