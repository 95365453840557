// Cake Mono UX
.cake-mono-light-ux {
  @include cake-mono-light-ux;
}

// Cake Mono
.cake-mono-light {
  @include cake-mono-light;
}

// Cake Mono Inverse
.cake-mono-inverse-regular {
  @include cake-mono-inverse-regular;
}

// Temp
.vandertak {
  @include vandertak;
}

.cake-sans-regular {
  @include cake-sans-regular;
}

.whale {
  @include whale;
}
