// Logo Contain

.logo-contain {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  max-width: 1200px;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

@include media-breakpoint-up(sm) {
  .logo-contain {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    padding-left: 0.8em;
    padding-right: 0.8em;
    max-width: 1200px;
  }
}

@include media-breakpoint-up(md) {
  .logo-contain {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 0.8em;
    padding-right: 0.8em;
    max-width: 1200px;
  }
}

@include media-breakpoint-up(lg) {
  .logo-contain {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 0.8em;
    padding-right: 0.8em;
    max-width: 1200px;
  }
}

@include media-breakpoint-up(xl) {
  .logo-contain {
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 0.8em;
    padding-right: 0.8em;
    max-width: 1200px;
  }
}
