// Fonts Contain

.fonts-contain {
  padding-top: 0em;
  padding-bottom: 3em;
  max-width: 1200px;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
}

@include media-breakpoint-up(sm) {
  .fonts-contain {
    padding-top: 0em;
    padding-bottom: 2em;
  }
}

@include media-breakpoint-up(md) {
  .fonts-contain {
    padding-top: 0em;
    padding-bottom: 2em;
  }
}

@include media-breakpoint-up(lg) {
  .fonts-contain {
    padding-top: 0em;
    padding-bottom: 2em;
  }
}

@include media-breakpoint-up(xl) {
  .fonts-contain {
    padding-top: 0em;
    padding-bottom: 2em;
  }
}

// Fonts Info

.fonts-info {
  padding-top: 1em;
  padding-bottom: 2em;
  padding-left: 0em;
  padding-right: 0em;
  border-bottom: 4px solid #000000;
  max-width: 1200px;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  // p {
  //   margin: 0;
  //   padding: 0;
  // }
  .info-header {
    font-size: 1.5rem;
    text-align: left;
  }

  .info-text {
    font-size: 1rem;
    text-align: left;
  }

  .info-name {
    font-size: 2.5rem;
  }

  .info-links {
    padding-top: 1em;
  }

  .info-desc {
    padding-top: 1em;
    white-space: pre-line;
  }
  // Info Desc
  @include media-breakpoint-up(sm) {
    .info-desc {
      padding-top: 1em;
    }
  }

  @include media-breakpoint-up(md) {
    .info-desc {
      padding-top: 0em;
      padding-left: 0.8em;
      padding-right: 1em;
    }
  }

  @include media-breakpoint-up(lg) {
    .info-desc {
      padding-top: 0em;
      padding-left: 0.8em;
      padding-right: 1em;
    }
  }

  @include media-breakpoint-up(xl) {
    .info-desc {
      padding-top: 0em;
      padding-left: 0.8em;
    }
  }
}

// Fonts Image

.fonts-image {
  padding-top: 2em;
  padding-bottom: 0.5em;
  padding-left: 0em;
  padding-right: 0em;
  max-width: 1200px;
  border-bottom: 4px solid #000000;
  .fonts-image-col {
    padding-bottom: 2em;
  }
}

// Fonts License

.fonts-license {
  padding-top: 1.4em;
  padding-bottom: 0em;
  padding-left: 0.3em;
  padding-right: 0.3em;
  max-width: 1200px;
  .subhead {
    text-transform: uppercase;
  }
  .license-name {
    text-transform: uppercase;
  }
  .card {
    background: none;
    padding-top: 1em;
    padding-bottom: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 1.5em;
    // height: 15em;
    border: 1px solid rgb(0, 0, 0);
    @include media-breakpoint-up(sm) {
      // height: 12em;
    }
    @include media-breakpoint-up(md) {
      height: 16em;
    }
    @include media-breakpoint-up(lg) {
      height: 14em;
    }
    @include media-breakpoint-up(xl) {
      height: 12em;
    }
    p {
      white-space: pre-line;
    }
  }
  .license-support:hover {
    background-color: rgba(0, 0, 0, 0.1);
    -moz-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
  }
  .license-active {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

// Fonts select
.fonts-select {
  padding-top: 0.5em;
  padding-bottom: 0em;
  padding-left: 0.3em;
  padding-right: 0.3em;
  max-width: 1200px;
  .subhead {
    text-transform: uppercase;
  }
  .license-font-name {
    font-size: 2.5rem;
  }
  .font-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid rgb(0, 0, 0);
    border-bottom: 1px solid rgb(0, 0, 0);
  }
  // .font-card:hover {
  //   background-color: rgba(0, 0, 0, 0.1);
  //   -moz-transition: all 0.2s ease-in;
  //   -o-transition: all 0.2s ease-in;
  //   -webkit-transition: all 0.2s ease-in;
  //   transition: all 0.2s ease-in;
  // }
  .part-card {
    background: none;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 1px solid rgb(0, 0, 0);
  }
  // .part-card:hover {
  //   background-color: rgba(0, 0, 0, 0.1);
  //   -moz-transition: all 0.2s ease-in;
  //   -o-transition: all 0.2s ease-in;
  //   -webkit-transition: all 0.2s ease-in;
  //   transition: all 0.2s ease-in;
  // }
  .trial-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid rgb(0, 0, 0);
    border-bottom: 1px solid rgb(0, 0, 0);
  }
  // .trial-card:hover {
  //   background-color: rgba(0, 0, 0, 0.1);
  //   -moz-transition: all 0.2s ease-in;
  //   -o-transition: all 0.2s ease-in;
  //   -webkit-transition: all 0.2s ease-in;
  //   transition: all 0.2s ease-in;
  // }
  .select-disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  .select-active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .added {
    opacity: 0;
  }
}
