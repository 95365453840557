// Color
$primary: #606060;
$secondary: #5a5a5a;
$gray-300: #eeeeee;

// Border
$border-width: 0px;

// Input
$input-btn-padding-x: 0;
$input-btn-padding-y: 0;

$input-padding-y: 0.5em;
$input-padding-x: 0.5em;
$input-padding-y-sm: 0.5em;
$input-padding-x-sm: 0.5em;
$input-padding-y-lg: 0.5em;
$input-padding-x-lg: 0.5em;

$input-bg: transparent;
$input-disabled-bg: gray;
$input-disabled-border-color: rgb(75, 75, 75);
$input-color: rgb(0, 0, 0);
$input-border-color: rgb(0, 0, 0);
$input-border-width: 1px;
$input-box-shadow: none;

$input-border-radius: 0px;
$input-border-radius-sm: 0px;
$input-border-radius-lg: 0px;

$input-focus-bg: transparent;
$input-focus-border-color: rgb(0, 0, 0);
$input-focus-color: rgb(0, 0, 0);
$input-focus-width: 1px;

$input-placeholder-color: rgba(0, 0, 0, 0.4);
$input-plaintext-color: rgb(0, 0, 0);

// Form Select
$form-select-bg: transparent;
$form-select-border-radius: 0px;

// Form Check
$form-check-input-bg: none;
$form-check-input-border: 1px solid #000000;
$form-check-input-border-radius: 0em;
$form-check-input-focus-border: none;
$form-check-input-focus-box-shadow: 0em;
$form-check-input-checked-color: #000000;
$form-check-input-checked-bg-color: none;
$form-check-input-checked-border-color: none;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 10 10'><rect x='2.2' y='2.2' width='5.6' height='5.6'/></svg>");

// Card
$card-border-width: 1px;
$card-border-radius: 0rem;

// Off Canvas
$offcanvas-bg-color: #000000;
$offcanvas-color: #ffffff;
// $offcanvas-horizontal-width: 500px;

// Button Close
$btn-close-color: #ffffff;
$btn-close-opacity: 1;
$btn-close-hover-opacity: 0.5;
$btn-close-focus-opacity: 1;
$btn-close-disabled-opacity: 1;

// Navbar
$navbar-toggler-padding-y: 0rem;
$navbar-toggler-padding-x: 0rem;
$navbar-toggler-border-radius: 0;
$navbar-toggler-focus-width: 0em;
$navbar-toggler-transition: box-shadow 0.15s ease-in-out;

$navbar-light-color: rgb(0, 0, 0);
$navbar-light-hover-color: rgb(0, 0, 0);
$navbar-light-active-color: rgb(0, 0, 0);
$navbar-light-disabled-color: rgb(49, 49, 49);
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
$navbar-light-toggler-border-color: rgb(0, 0, 0);

// Nav Tabs
$nav-link-padding-y: 0.5rem;
$nav-link-padding-x: 1rem;
$nav-link-font-size: null;
$nav-link-font-weight: null;
$nav-link-color: rgb(0, 0, 0);
$nav-link-hover-color: rgb(0, 0, 0);

$nav-tabs-border-color: rgb(0, 0, 0);
$nav-tabs-border-width: 1px;
$nav-tabs-border-radius: 0px;
$nav-tabs-link-hover-border-color: rgb(0, 0, 0);
$nav-tabs-link-active-color: rgb(0, 0, 0);
$nav-tabs-link-active-bg: none;
$nav-tabs-link-active-border-color: rgb(0, 0, 0);

// Tables
$table-border-factor: 0.1;
$table-border-width: 1px;
$table-border-color: rgb(0, 0, 0);

$table-variants: (
  "dark": rgb(0, 0, 0),
);
