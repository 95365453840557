//Font and Icon Imports
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");

// Core variables mixins and webfonts
@import "variables";
@import "webfonts";
@import "mixins";
@import "keyframes";

// Bootstrap and its default variables
@import "../../../node_modules/bootstrap/scss/bootstrap.scss";

// Global CSS
@import "color";
@import "body";
@import "navigation";
@import "offcanvas";
@import "logo";
@import "home";
@import "fonts";
@import "range";
@import "collection";
@import "tester";
@import "response";
@import "section";
@import "account";
@import "cart";
@import "checkout";
@import "footer";
