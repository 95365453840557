// Cart Section
.cart-section {
  padding-top: 0.5em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  max-width: 1200px;
  .subhead {
    text-transform: uppercase;
    padding-bottom: 1.5em;
  }
  .cart-font-name {
    font-size: 2.5rem;
  }
  .cart-fill {
    font-size: 2.5rem;
    color: rgba(0, 0, 0, 0);
  }
  .cart-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid rgb(0, 0, 0);
    border-bottom: 1px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .cart-part-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 1px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .cart-vat-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px solid rgb(0, 0, 0);
    border-bottom: 0px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .cart-total-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 4px solid rgb(0, 0, 0);
    border-bottom: 4px solid rgb(0, 0, 0);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .cart-select {
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  @include media-breakpoint-up(sm) {
    .cart-select {
      margin-top: 0.5em;
      margin-bottom: 1em;
    }
  }
  @include media-breakpoint-up(md) {
    .cart-select {
      margin-top: 0.5em;
      margin-bottom: 1em;
    }
  }
  @include media-breakpoint-up(lg) {
    .cart-select {
      margin-top: 0em;
      margin-bottom: 0em;
    }
  }
  @include media-breakpoint-up(xl) {
    .cart-select {
      margin-top: 0em;
      margin-bottom: 0em;
    }
  }
  .cart-form-link {
    padding-top: 2em;
  }
  .remove-item {
    color: #000000;
    text-decoration: underline;
  }
  .remove-item:hover {
    text-decoration: none;
  }
}

// Cart Summary Section
.summary-section {
  padding-top: 0.5em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  max-width: 1200px;
  .cart-fill {
    color: rgba(0, 0, 0, 0);
  }
  .cart-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid rgb(255, 255, 255);
    border-bottom: 1px solid rgb(255, 255, 255);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .cart-part-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 1px solid rgb(255, 255, 255);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .cart-vat-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px solid rgb(255, 255, 255);
    border-bottom: 0px solid rgb(255, 255, 255);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .cart-total-card {
    background: none;
    border-left: 0px;
    border-right: 0px;
    border-top: 4px solid rgb(255, 255, 255);
    border-bottom: 4px solid rgb(255, 255, 255);
    .card-body {
      padding-left: 0em;
      padding-right: 0em;
    }
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .cart-select {
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  @include media-breakpoint-up(sm) {
    .cart-select {
      margin-top: 0.5em;
      margin-bottom: 1em;
    }
  }
  @include media-breakpoint-up(md) {
    .cart-select {
      margin-top: 0.5em;
      margin-bottom: 1em;
    }
  }
  @include media-breakpoint-up(lg) {
    .cart-select {
      margin-top: 0em;
      margin-bottom: 0em;
    }
  }
  @include media-breakpoint-up(xl) {
    .cart-select {
      margin-top: 0em;
      margin-bottom: 0em;
    }
  }
  .cart-form-link {
    padding-top: 2em;
  }
}

// Cart Checkout Section
.cart-checkout-section {
  padding-top: 0em;
  padding-bottom: 2.5em;
  padding-left: 0em;
  padding-right: 0em;
  max-width: 1200px;
}
