// Section Contain

.section-contain {
  padding-top: 1em;
  padding-bottom: 3em;
  max-width: 1200px;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  p {
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }
  .header {
    font-size: 2.5rem;
    padding-bottom: 0.5rem;
    text-transform: uppercase;
  }
  .header-text {
    padding-bottom: 1.5rem;
  }
  .subhead {
    text-transform: uppercase;
  }
  .subhead-link {
    text-transform: none;
  }
  .support-head-info {
    padding-bottom: 2em;
  }
  .main-text {
    font-size: 1rem;
  }
  .select-disabled {
    pointer-events: none;
    opacity: 0.4;
  }
  .support-section-info {
    border-top: 4px solid #000000;
    padding-bottom: 2em;
  }
  .support-section-info-last {
    border-top: 4px solid #000000;
    padding-bottom: 0em;
  }
  .support-section-subheads {
    border-bottom: 1px solid #000000;
    padding-top: 2em;
    padding-bottom: 2em;
  }
  .support-section-subheads-last {
    padding-top: 2em;
    padding-bottom: 0em;
  }
  .support-section-name {
    text-transform: uppercase;
  }
  .support-section-desc {
    white-space: pre-line;
  }
  .support-section-link {
    padding-top: 1em;
  }
}

@include media-breakpoint-up(sm) {
  .section-contain {
    padding-top: 1em;
    padding-bottom: 3em;
    max-width: 1200px;
  }
}

@include media-breakpoint-up(md) {
  .section-contain {
    padding-top: 1em;
    padding-bottom: 3em;
    max-width: 1200px;
  }
}

@include media-breakpoint-up(lg) {
  .section-contain {
    padding-top: 1em;
    padding-bottom: 3em;
    max-width: 1200px;
  }
}

@include media-breakpoint-up(xl) {
  .section-contain {
    padding-top: 1em;
    padding-bottom: 3em;
    max-width: 1200px;
  }
}
