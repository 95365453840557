.offcanvas {
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #ffffff;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
  .offcanvas-header {
    text-align: right;
  }
}
