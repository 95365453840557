// List Contain
.list-contain {
  max-width: 1200px;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
}

.list-info {
  padding-top: 1em;
  padding-left: 0em;
  padding-right: 0em;
  padding-bottom: 1em;
  border-top: 4px solid #000000;
  @include cake-mono-light;
  .pipe {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }
}

// Footer Contain
.footer-contain {
  max-width: 1200px;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
}

.footer-info {
  padding-top: 0em;
  padding-left: 0em;
  padding-right: 0em;
  padding-bottom: 3em;
  @include cake-mono-light;
  .pipe {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }
}

.single footer {
  position: fixed;
  height: 100px;
  bottom: 0;
  width: 100%;
}
