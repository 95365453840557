// Nav Contain
.nav-contain {
  max-width: 1200px;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
  a {
    color: #000000;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
}

// Nav Large
.navigation-large {
  padding-top: 0em;
  padding-left: 0em;
  padding-right: 0em;
  padding-bottom: 0em;
  border-top: 4px solid #000000;
  border-bottom: 4px solid #000000;
  .section-nav {
    padding: 0.35em 0em 0em 0em;
    margin-bottom: 0.4em;
    font-size: 1rem;
  }
  .pipe {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }
}

// Nav Small
.navigation-small {
  padding-top: 0em;
  padding-left: 0.2em;
  padding-right: 0.2em;
  padding-bottom: 0em;
  border-top: 4px solid #000000;
  border-bottom: 4px solid #000000;
  .section-nav {
    padding: 0.35em 0em 0em 0em;
    margin-bottom: 0.4em;
    font-size: 1rem;
  }
  .pipe {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }
  .btn-link {
    color: #000000;
    text-decoration: underline;
  }
  .btn-link:hover {
    text-decoration: none;
  }
}

// Nav Overflow
.nav-overflow-list {
  list-style-type: none;
  padding: 0;
  font-size: 1rem;
  line-height: 2rem;
}

// Nav Sticky
.nav-sticky {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}
